import catalogoApi from "../src/api/catalogoApi";

export default {
  state: {
    catalogo: []
  },

  getters: {
    getCatalogo: (state) => state.catalogo
  },

  mutations: {
    setCatalogo: (state, catalogo) => {
      state.catalogo = catalogo;
    }
  },

  actions: {
    getCatalogoAsync: async ({ commit }) => {
      commit("setLoading", true);
      var items = await catalogoApi.get();
      commit("setCatalogo", items);
      commit("setLoading", false);
    },

    setItemInCatalogoAsync: async ({ dispatch }, item) => {
      await catalogoApi.set(item);
      dispatch("getCatalogoAsync");
    },

    deleteItemInCatalogoAsync: async ({ dispatch }, item) => {
      await catalogoApi.delete(item);
      dispatch("getCatalogoAsync");
    },

    updateItemInCatalogoAsync: async ({ dispatch }, item) => {
      await catalogoApi.update(item);
      dispatch("getCatalogoAsync");
    }
  }
};
