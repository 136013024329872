/* eslint-disable */
import Vuex from "vuex";
import Vue from "vue";
import catalogoStore from "../storeSections/catalogo";
import homeStore from "../storeSections/home";
import ordiniStore from "../storeSections/ordini";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menu: [
      { icon: "mdi-book-open-variant", title: "Home", route: "/" },
      { icon: "mdi-book-open-variant", title: "Catalogo", route: "/catalogo" },
      { icon: "mdi-book-open-variant", title: "Ordini", route: "/ordini" }
    ],
    ...catalogoStore.state,
    ...homeStore.state,
    ...ordiniStore.state,
    loading: false
  },

  getters: {
    ...catalogoStore.getters,
    ...homeStore.getters,
    ...ordiniStore.getters,
    getLoading: (state) => state.loading
  },

  mutations: {
    ...catalogoStore.mutations,
    ...homeStore.mutations,
    ...ordiniStore.mutations,
    setLoading: (state, loading) => {
      state.loading = loading;
    }
  },

  actions: {
    ...catalogoStore.actions,
    ...homeStore.actions,
    ...ordiniStore.actions
  }
});
