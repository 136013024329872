import ordiniApi from "../src/api/ordiniApi";

export default {
  state: {
    ordini: []
  },

  getters: {
    getOrdini: (state) => state.ordini
  },

  mutations: {
    setOrdini: (state, ordini) => {
      state.ordini = ordini;
    }
  },

  actions: {
    getOrdiniAsync: async ({ commit }) => {
      commit("setLoading", true);
      var items = await ordiniApi.get();
      commit("setOrdini", items);
      commit("setLoading", false);
    },

    setOrdineAsync: async ({ commit, dispatch }, item) => {
      commit("setLoading", true);
      await ordiniApi.set(item);
      dispatch("getOrdiniAsync");
    },

    deleteOrdineAsync: async ({ commit, dispatch }, item) => {
      commit("setLoading", true);
      await ordiniApi.delete(item);
      dispatch("getOrdiniAsync");
    },

    updateOrdineAsync: async ({ commit, dispatch }, item) => {
      commit("setLoading", true);
      await ordiniApi.update(item);
      dispatch("getOrdiniAsync");
    }
  }
};
