import HomeApi from "../src/api/homeApi";

export default {
  state: {
    home: []
  },

  getters: {
    getHome: (state) => state.home
  },

  mutations: {
    setHome: (state, home) => {
      state.home = home;
    }
  },

  actions: {
    getHomeAsync: async ({ commit }) => {
      commit("setLoading", true);
      var items = await HomeApi.get();
      commit("setHome", items);
      commit("setLoading", false);
    },

    setHomeAsync: async ({ dispatch }, item) => {
      await HomeApi.set(item);
      dispatch("getHomeAsync");
    },

    deleteHomeAsync: async ({ dispatch }, item) => {
      await HomeApi.delete(item);
      dispatch("getHomeAsync");
    },

    updateHomeAsync: async ({ dispatch }, item) => {
      await HomeApi.update(item);
      dispatch("getHomeAsync");
    }
  }
};
