<template>
  <v-app v-scroll="onScroll">
    <TabView :scrolled="scrolled" />
    <router-view></router-view>
  </v-app>
</template>

<script>
import TabView from "./components/TabView";
import { mapState } from "vuex";

export default {
  name: "App",

  components: {
    TabView
  },

  data: () => ({
    scrolled: 0,
    play: false
  }),

  computed: {
    ...mapState({
      menu: (state) => state.menu
    })
  },

  methods: {
    onScroll(e) {
      this.scrolled = e.target.scrollingElement.scrollTop;
    }
  }
};
</script>

<style lang="scss">
@import "./app";
</style>
