import VueRouter from "vue-router";
import Vue from "vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("./views/SezioneHome.vue")
  },
  {
    path: "/catalogo",
    name: "catalogo",
    component: () => import("./views/SezioneCatalogo.vue")
  },
  {
    path: "/ordini",
    name: "ordini",
    component: () => import("./views/SezioneOrdini.vue")
  },
];

export default new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes: routes
});
