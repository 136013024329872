import axios from "axios";

export default {
  baseUrl: process.env.VUE_APP_API_BASE + "/catalogo",

  async get() {
    try {
      var res = await axios.get(this.baseUrl);
      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async set(item) {
    try {
      await axios.post(this.baseUrl, item);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async delete(item) {
    try {
      await axios.delete(this.baseUrl, { data: item });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async update(item) {
    try {
      await axios.put(this.baseUrl, item);
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
};
